import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import moment from "moment";
import reportService from "./reports-service";
import exportLimit from "../../common/export-limit.vue";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      sDate: "",
      eDate: "",
      showStartDate: true,
      showEndDate: true,
      startDate: false,
      endDate: false,
      selectedProject: "",
      projectList: [],
      selectedBusiness: "",
      businessData: [],
      selectedWarehouse: "",
      warehouseData: [],
      dateSelected: false,
      runDisabled: true,
      showBusiness: false,
      showWarehouse: false,
      dailySnapshotData: [],
      excelName: "",
      totalRecords: 0,
      exportDisable: true,
      export50kData: false,
      exportDialog: false,
      showData: false,
      apiObj: {
        pass: 0,
        userid: 0,
        proj_key: 0,
        part_num: "",
        bu_key: 0,
        ware_key: 0,
        lcsdate: "",
        lcedate: "",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.eDate ? moment(this.eDate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.selectedProject = "";
      this.projectList = [];
      this.selectedBusiness = "";
      this.businessData = [];
      this.selectedWarehouse = "";
      this.warehouseData = [];
      this.dateSelected = false;
      this.runDisabled = true;
      this.showBusiness = false;
      this.showWarehouse = false;
      this.dailySnapshotData = [];
      this.excelName = "";
      this.totalRecords = 0;
      this.exportDisable = true;
      this.export50kData = false;
      this.exportDialog = false;
      this.showData = false;
      this.apiObj = {
        pass: 0,
        userid: 0,
        proj_key: 0,
        part_num: "",
        bu_key: 0,
        ware_key: 0,
        lcsdate: "",
        lcedate: "",
      };
    },
    // set end Date
    setEndDate() {
      let minEndDate = new Date(this.sDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    //On click continue
    async onContinue() {
      !this.sDate ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : "";
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.userid = parseInt(this.userId);
      let showMessage = false;
      let projData = await reportService.dailySnapshot("post", this.apiObj, showMessage);
      if (projData.message !== "NA") {
        this.projectList = projData;
        this.dateSelected = true;
        this.totalRecords = 0;
        this.exportDisable = true;
        this.export50kData = false;
        this.exportDialog = false;
        this.dailySnapshotData = [];
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Data Available change the Start Date",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.dateSelected = false;
      }
    },
    //Project Selected
    async onChangeProject() {
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.pass = 1;
      this.apiObj.proj_key = parseInt(this.selectedProject);
      let showMessage = false;
      let busData = await reportService.dailySnapshot("post", this.apiObj, showMessage);
      if (busData.message !== "NA") {
        this.businessData = busData;
        this.selectedBusiness = "";
        this.selectedWarehouse = "";
        this.showBusiness = true;
        this.showWarehouse = false;
        this.runDisabled = true;
        this.dailySnapshotData = [];
        this.totalRecords = 0;
        this.exportDisable = true;
        this.showData = false;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Business based on the selected Project",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.selectedBusiness = "";
        this.selectedWarehouse = "";
        this.showBusiness = false;
        this.showWarehouse = false;
        this.runDisabled = true;
        this.dailySnapshotData = [];
        this.totalRecords = 0;
        this.exportDisable = true;
        this.showData = false;
      }
    },
    //Business selected
    async businessSelected() {
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.pass = 2;
      this.apiObj.proj_key = parseInt(this.selectedProject);
      this.apiObj.bu_key = parseInt(this.selectedBusiness);
      let showMessage = false;
      let otherData = await reportService.dailySnapshot("post", this.apiObj, showMessage);
      if (otherData.message !== "NA") {
        this.warehouseData = otherData;
        this.selectedWarehouse = "";
        this.showWarehouse = true;
        this.runDisabled = true;
        this.dailySnapshotData = [];
        this.totalRecords = 0;
        this.exportDisable = true;
        this.showData = false;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Warehouse based on the selected Business",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.selectedWarehouse = "";
        this.showWarehouse = true;
        this.runDisabled = true;
        this.dailySnapshotData = [];
        this.totalRecords = 0;
        this.exportDisable = true;
        this.showData = false;
      }
    },
    //Warehouse Selected
    warehouseSelected() {
      this.dailySnapshotData = [];
      this.totalRecords = 0;
      this.exportDisable = true;
      this.runDisabled = false;
      this.showData = false;
    },
    //Run Report
    async runReport() {
      this.apiObj.lcsdate = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.lcedate = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.userid = parseInt(this.userId);
      this.apiObj.pass = 3;
      this.apiObj.proj_key = parseInt(this.selectedProject);
      this.apiObj.bu_key = parseInt(this.selectedBusiness);
      this.apiObj.ware_key = parseInt(this.selectedWarehouse);
      let showMessage = false;
      let reportData = await reportService.dailySnapshot("post", this.apiObj, showMessage);
      if (reportData.message !== "NA") {
        if (reportData.length > 0) {
          this.dailySnapshotData = reportData;
          this.totalRecords = reportData.length;
          this.exportDisable = false;
          this.showData = true;
        } else {
          this.dailySnapshotData = [];
          this.totalRecords = 0;
          this.exportDisable = true;
          this.showData = false;
          let Alert = {
            type: "error",
            isShow: true,
            message: "No records based on the selected criteria",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    async exportToExcel() {
      if (this.totalRecords <= 15000) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Daily WIP Transactions');

        // Style configuration
        const headerStyle = {
          font: { bold: true },
          alignment: { horizontal: 'center', vertical: 'middle' },
          border: {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' }
          },
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } }
        };

        const colors = {
          grey: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } },
          lightPurple: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'CCC0DA' } },
          blue: { type: 'pattern', pattern: 'solid', fgColor: { argb: '8DB4E2' } },
          yellow: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF00' } },
          green: { type: 'pattern', pattern: 'solid', fgColor: { argb: '92D050' } },
          lightBlue: { type: 'pattern', pattern: 'solid', fgColor: { argb: '92CDDC' } },
          orange: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FABF8F' } },
          brown: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C4BD97' } },
          lightGrey: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DBDDE9' } }
        };

        const borderStyle = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' }
        };

        // Add Header Rows
        worksheet.addRow([]);
        worksheet.addRow(['', 'Daily WIP Transactions', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Fulfilment', '', '', 'Bucket Status', '', '', '', '', '', '', '', '', '', '', '']);
        worksheet.addRow(['Date', 'Open Orders', 'Dock Door Deliveries', '', 'RLP', 'Initial Test', '', 'Pre-Final Test', '', 'Repair', '', '', 'Firmware', '', 'Post-Final Test', '', 'CI', '', 'Audit', '', 'Open Fulfilment', 'Shipped', 'Missed', '']);
        worksheet.addRow(['', 'ASN Loaded', 'Dock', 'Discrepancy', 'Received', 'NFF', 'Fail', 'Pass', 'Fail', 'Repair', 'Scrap', 'WFP', 'Pass', 'Fail', 'Pass', 'Fail', 'Pass', 'Fail', 'Pass', 'Fail', '', '', '', 'TBE', 'Scrap', 'Repair', 'Waiting For Parts', 'Engineering Evaluation', 'Firmware', 'Final Test(Pre-Repair)', 'Final Test(Post-Repair)', 'Cosmetic Inspection', 'Out Of Box Audit(OBA)', 'FGI', 'Total']);

        // Merge cells as in your table
        worksheet.mergeCells('B2:T2');
        worksheet.mergeCells('U2:W2');
        worksheet.mergeCells('X2:AI2');
        worksheet.mergeCells('U3:U4');
        worksheet.mergeCells('V3:V4');
        worksheet.mergeCells('W3:W4');
        worksheet.mergeCells('X3:AI3');
        worksheet.mergeCells('J3:L3');
        worksheet.mergeCells('F3:G3');
        worksheet.mergeCells('H3:I3');
        worksheet.mergeCells('M3:N3');
        worksheet.mergeCells('O3:P3');
        worksheet.mergeCells('Q3:R3');
        worksheet.mergeCells('S3:T3');




        // Apply styles to the header rows
        worksheet.getRow(2).eachCell((cell) => {
          cell.style = { ...headerStyle, fill: colors.grey };
          cell.border = borderStyle;
        });
        worksheet.getRow(3).eachCell((cell, colNumber) => {
          switch (colNumber) {
            case 1:
              cell.style.fill = colors.lightGrey; break;
            case 2: case 3: case 4: case 5:
              cell.style.fill = colors.lightPurple; break;
            case 6: case 7:
              cell.style.fill = colors.blue; break;
            case 8: case 9:
              cell.style.fill = colors.yellow; break;
            case 10: case 11: case 12:
              cell.style.fill = colors.green; break;
            case 13: case 14:
              cell.style.fill = colors.green; break;
            case 15: case 16: case 17: case 18: case 19: case 20:
              cell.style.fill = colors.lightBlue; break;
            case 21: case 22: case 23:
              cell.style.fill = colors.orange; break;
            case 24: case 25: case 26:
              cell.style.fill = colors.brown; break;
            default:
              break;
          }
          cell.border = borderStyle;
        });

        worksheet.getRow(4).eachCell((cell, colNumber) => {
          switch (colNumber) {
            case 1:
              cell.style.fill = colors.lightGrey; break;
            case 2: case 3: case 4: case 5:
              cell.style.fill = colors.lightPurple; break;
            case 6: case 7:
              cell.style.fill = colors.blue; break;
            case 8: case 9:
              cell.style.fill = colors.yellow; break;
            case 10: case 11: case 12:
              cell.style.fill = colors.green; break;
            case 13: case 14:
              cell.style.fill = colors.green; break;
            case 15: case 16: case 17: case 18: case 19: case 20:
              cell.style.fill = colors.lightBlue; break;
            case 21: case 22: case 23:
              cell.style.fill = colors.orange; break;
            case 24: case 25: case 26: case 27: case 28: case 29: case 30: case 31: case 32: case 33: case 34:
              cell.style.fill = colors.brown; break;
            case 35:
              cell.style.fill = colors.lightGrey; break;

          }
          cell.border = borderStyle;
        });

        // Add your data rows
        this.dailySnapshotData.forEach((item, rowIndex) => {
          const row = worksheet.addRow([
            item.cdate, item.asn_loaded, item.dock, item.discrepancy, item.received,
            item.it_nff, item.it_fail, item.pre_ft_pass, item.pre_ft_fail,
            item.rep_repaired, item.rep_scrapped, item.rep_wfp, item.firm_pass, item.firm_fail,
            item.post_ft_pass, item.post_ft_fail, item.ci_pass, item.ci_fail,
            item.audit_pass, item.audit_fail, item.cf_open, item.cf_shipped,
            item.cf_missed, item.TBE, item.SCRAP, item.Repair, item["Waiting-For-Parts"],
            item["Engineering Evaluation"], item.Firmware, item["Final Test (Pre-Repair)"],
            item["Final Test (Post-Repair)"], item["Cosmetic Inspection"],
            item["Out-Of-Box Audit (OBA)"], item.FGI, item.Total
          ]);


          row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            // Apply light gray background to column A (first column)
            if (colNumber === 1) {
              cell.style.fill = colors.grey;
            }

            // Apply light gray background to the last row
            if (rowIndex === this.dailySnapshotData.length - 1) {
              cell.style.fill = colors.lightGrey;
            }

            // Apply borders
            cell.border = borderStyle;
          });
        });

        // Download the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), Utility.excelFileName("Daily_SnapShot"));
      }
      else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },

    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
